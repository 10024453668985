<template>
  <qrcode-stream
    :pause="isCoolDown"
    @detect="handleDetect"
    @camera-on="onReady"
    @error="onError"
  >
    <Transition>
      <div v-if="ready" class="pint">
        <p>
          {{ props.informationText ?? '店内のQRコードをスキャンしてください' }}
        </p>
      </div>
      <div v-else class="d-flex align-center justify-center fill-height">
        <v-progress-circular
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </Transition>
    <DebugComponent style="position: absolute; bottom: 0">
      <v-btn text="Debug:Emit" style="opacity: 0.5" @click="emitTest"></v-btn>
      <v-btn
        text="Debug:Error"
        style="opacity: 0.5"
        @click="emitErrorTest"
      ></v-btn>
    </DebugComponent>
  </qrcode-stream>
</template>

<script setup lang="ts">
import { QrReaderMediaError } from '~/utils/media-error'

type DetectCode = {
  rawValue: string
}

const props = defineProps({
  scanCoolTime: {
    type: Number,
    default: 500,
  },
  informationText: {
    type: String,
    default: null,
  },
})

const emits = defineEmits<{
  (e: 'detect', value: string): void
  (e: 'error', value: string): void
}>()

const ready = ref(false)
const isCoolDown = ref(false)

const onReady = () => {
  ready.value = true
}

const handleDetect = async (detectedCodes: DetectCode[]) => {
  isCoolDown.value = true
  emits('detect', detectedCodes[0].rawValue)
  // 1回送信してからscanCoolTime[ms]の間、送信しない状態にする。
  await sleep(props.scanCoolTime)
  isCoolDown.value = false
}

const sleep = async (ms: number) => {
  await new Promise((resolve) => setTimeout(resolve, ms))
}

const emitTest = () => {
  handleDetect([{ rawValue: 'http://localhost:3002/brands/1' }])
}

const onError = (error: QrReaderMediaError) => {
  const message = getQrReaderMediaErrorMessage(error.name)
  emits('error', message)
}

const emitErrorTest = () => {
  const message = getQrReaderMediaErrorMessage('NotAllowedError')
  emits('error', message)
}
</script>

<style scoped>
.pint {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  background-image: url('~/assets/images/qr/pint.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-attachment: fixed;
}

.pint p {
  color: white;
  position: relative;
  top: calc(65%);
}

.v-enter-active {
  transition: opacity 0.25s ease;
}
.v-leave-leave {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
